<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มเจ้าหน้าที่</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="form.activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.firstname"
              label="ชื่อ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">นามสกุล</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.lastname"
              label="นามสกุล"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">เบอร์โทรติดต่อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="form.tel"
              label="เบอร์โทรติดต่อ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">วัน เดือน ปีเกิด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-menu
              ref="menufrom"
              v-model="menufrom"
              :close-on-content-click="false"
              :return-value.sync="form.birthDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.birthDate"
                  label="วัน เดือน ปีเกิด"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                  :rules="rules.required"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.birthDate" no-title scrollable>
                <v-btn text color="#234baa" @click="menufrom = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#234baa"
                  @click="$refs.menufrom.save(form.birthDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประวัติการทำงาน</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-textarea
              v-model="form.jobDesc"
              label="ประวัติการทำงาน"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">โลเคชั่นบ้าน</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="3" sm="3">
            <v-text-field
              v-model="form.lat"
              label="ละติจูด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-text-field
              v-model="form.lng"
              label="ลองติจูด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">สถานที่ทำงาน</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              label="สถานที่ทำงาน"
              v-model="form.companyId"
              :items="items"
              item-text="companyName"
              item-value="id"
              solo
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ตำแหน่ง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              label="เลือกตำแหน่ง"
              :items="itemsPosition"
              item-text="companyName"
              item-value="id"
              solo
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูป</span>
                  <span style="color: red">*</span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#234baa" dark>บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {
        site: "",
        firstname: "",
        lastname: "",
        tel: "",
        birthDate: "",
        jobDesc: "",
        lat: "",
        lng: "",
        imgUrl: "",
        file: "",
        userType: "USER",
        activeFlag: true,
        companyid: null
      },
      menufrom: false,
      items: [],
      itemsPosition: [
        'ตำแหน่ง 1',
        'ตำแหน่ง 2',
        'ตำแหน่ง 3'
      ],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.getAllCompany();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllCompany() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/companies/
          `
      );
      console.log("getAllcompanies", response.data);
      this.items = response.data.data;
    },
    // async getAllCompany() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllCompanys", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageOfficer");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users`,
          this.form,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างเจ้าหน้าที่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageOfficer");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
